Vue.component('v-header',{
    props:[
        'productlist',
        'category',
        'current'
    ],
    data: function(){
        return{
        }
    },
    template:`
    <div class="u-header__section">
        <div class="container">
            <div class="top-nav py-3 pt-md-1 pb-md-0">
                <a class="navbar-brand u-header__navbar-brand" href="/index.html">
                    <img src="/images/cavige-dark.png" alt="Logo">
                    <span class="d-none d-md-inline u-header__navbar-link">北京中航通用科技有限公司</span>
                </a>
                <div class="d-flex align-items-center">
                    <div id="nav_search" class="position-relative d-none d-sm-inline-block">
                        <a href="#" class="u-header__navbar-link">
                        <span class="far fa-search u-btn--icon__inner font-size-14"></span>
                        </a>
                    </div>
                    <!-- Language -->
                    <div class="position-relative d-inline-block">
                        <a id="languageDropdownInvoker" class="dropdown-nav-link dropdown-toggle d-flex align-items-center" href="javascript:;"
                           aria-controls="languageDropdown"
                           aria-expanded="false"
                           data-unfold-event="click"
                           data-unfold-target="#languageDropdown"
                           data-unfold-type="css-animation"
                           data-unfold-duration="300"
                           data-unfold-delay="300"
                           data-unfold-hide-on-scroll="true"
                           data-unfold-animation-in="slideInUp"
                           data-unfold-animation-out="fadeOut">
                            <img class="dropdown-item-icon" src="/assets/vendor/flag-icon-css/flags/4x3/cn.svg" alt="SVG">
                            <span class="d-inline-block d-sm-none">CN</span>
                            <span class="d-none d-sm-inline-block">中文</span>
                        </a>

                        <div id="languageDropdown" class="dropdown-menu dropdown-unfold u-unfold--css-animation u-unfold--hidden mt-2" aria-labelledby="languageDropdownInvoker">
                            <a class="active dropdown-item" href="#">中文</a>
                            <a class="dropdown-item" href="#">English</a>
                            <a class="dropdown-item" href="#">Español‎</a>
                        </div>
                    </div>
                    <!-- Responsive Toggle Button -->
                    <button type="button" class="ml-2 navbar-toggler btn d-md-none u-hamburger"
                            aria-label="Toggle navigation"
                            aria-expanded="false"
                            aria-controls="navBar"
                            data-toggle="collapse"
                            data-target="#navBar">
                        <span id="hamburgerTrigger" class="u-hamburger__box">
                            <span class="u-hamburger__inner"></span>
                        </span>
                    </button>
                    <!-- End Responsive Toggle Button -->
                </div>

            </div>
            <nav class="main-nav js-mega-menu navbar navbar-expand-md u-header__navbar font-size-15 u-header-left-aligned-nav py-0">
                <div id="navBar" class="collapse navbar-collapse py-0">
                    <ul class="navbar-nav u-header__navbar-nav">
                        <li class="position-relative d-none d-md-block">
                            <a id="dropdownOnHoverInvoker" class="nav-link u-header__nav-link position-relative dropdown-toggle" 
                               :class="current=='product'?'activate':''" href="javascript:"
                               aria-controls="dropdownOnHover"
                               aria-haspopup="true"
                               aria-expanded="false"
                               data-unfold-event="click"
                               data-unfold-target="#dropdownOnHover"
                               data-unfold-type="css-animation"
                               data-unfold-duration="300"
                               data-unfold-delay="300"
                               data-unfold-hide-on-scroll="false"
                               data-unfold-animation-in="slideInUp"
                               data-unfold-animation-out="fadeOut">
                                产品线
                            </a>
                            <slot></slot>
                        </li>
                        <li class="nav-item hs-has-sub-menu u-header__nav-item d-md-none"
                          data-event="hover"
                          data-animation-in="slideInUp"
                          data-animation-out="fadeOut">
                        <a id="blogMegaMenu" class="nav-link u-header__nav-link u-header__nav-link-toggle" href="javascript:;" aria-haspopup="true" aria-expanded="false" aria-labelledby="blogSubMenu">产品线</a>
                        <!-- Submenu -->
                        <ul id="blogSubMenu" class="hs-sub-menu u-header__sub-menu" aria-labelledby="blogMegaMenu" style="min-width: 230px;">
                          <!-- Classic -->
                          <li v-for="(cate,idx) in category" class="hs-has-sub-menu">
                            <a :id="'navLink'+idx" class="nav-link u-header__sub-menu-nav-link u-header__sub-menu-nav-link-toggle" href="javascript:;" aria-haspopup="true" aria-expanded="false" :aria-controls="'navSubmenu'+idx">{{cate}}</a>
                        
                            <ul :id="'navSubmenu'+idx" class="hs-sub-menu u-header__sub-menu" :aria-labelledby="'navLink'+idx" style="min-width: 230px;">
                              <li v-for="(item,index) in productlist" v-if="item.category.indexOf(idx)>=0">
                                <a class="nav-link u-header__sub-menu-nav-link" :href="'/product/'+item.id">{{ item.name }}</a>
                              </li>
                            </ul>
                          </li>
                          <!-- Classic -->
                        </ul>
                        <!-- Submenu -->
                        </li>
                        <li><a :class="current=='index'?'activate':''" href="/index.html" class="nav-link u-header__nav-link position-relative">首页</a></li>
                        <li><a :class="current=='about'?'activate':''" href="/about.html" class="nav-link u-header__nav-link position-relative">关于我们</a></li>
                        <li><a :class="current=='job'?'activate':''" href="/job.html" class="nav-link u-header__nav-link position-relative">人才</a></li>
                        <li><a :class="current=='news'?'activate':''" href="/news.html" class="nav-link u-header__nav-link position-relative">新闻</a></li>
                        <li><a :class="current=='forums'?'activate':''" href="/forums/" class="nav-link u-header__nav-link position-relative">论坛</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>`
});
Vue.component('product-chooser',
  {
      props:[
          'productlist',
          'category',
      ],
      data: function () {
          return{
              activeCate: 0,
              activeProduct: 0,
              icon: [
                  "fa-stream",
                  "fa-terminal",
                  "fa-hdd",
                  "fa-cube",
                  "fa-project-diagram",
              ]
          }
      },
      methods: {
          setProduct: function (id) {
              this.activeProduct = id;
          },
          setCate: function (id) {
              this.activeCate = id;
          }
      },
      template:`
        <div id="dropdownOnHover" class="dropdown-menu dropdown-unfold mt-0 min-width-80vw" aria-labelledby="dropdownOnHoverInvoker">
            <div class="product-display row mx-0">
                <div class="col-md-3 product-overview px-0">
                    <div v-for="(cate,index) in category" class="cate-wrap" 
                        @click="setCate(index)" 
                        :class="activeCate==index ? 'active' : ''">
                        <div class="category d-flex justify-content-between align-items-center">
                            <span>{{category[index]}}</span>
                            <span>
                                <i class="far" :class="icon[index]"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <i class="far fa-angle-right"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-9 product-series px-0">
                    <div class="product-cover position-relative">
                        <div class="product-chooser">
                            <div v-for="(item,index) in productlist" class="item-wrap" 
                                @mouseover="setProduct(index)" :class="item.category.indexOf(activeCate)>=0 ? (index==activeProduct ? 'active' : '') : 'd-none'">
                                <a :href="'/product/'+item.id" class="item">
                                    {{ item.name }}
                                </a>
                            </div>
                        </div>
                        <div class="product-descript p-4 d-flex flex-column align-items-center">
                            <img class="img-fluid w-60 space-top-1" :src="productlist[activeProduct].cover" alt="">
                        </div>
                        <img class="img-fluid bg-cover" src="/assets/img/1920x1080/img18.jpg" alt="">
                    </div>
                    <div class="product-details d-flex flex-row" v-if="productlist[activeProduct].spot[0]">
                        <div class="product-info u-ver-divider">
                            <h2>{{productlist[activeProduct].spot[0].value}}</h2>
                            <p class="small">{{productlist[activeProduct].spot[0].key}}</p>
                        </div>
                        <div class="product-info u-ver-divider" v-if="productlist[activeProduct].spot[1]">
                            <h2>{{productlist[activeProduct].spot[1].value}}</h2>
                            <p class="small">{{productlist[activeProduct].spot[1].key}}</p>
                        </div>
                        <div class="product-info" v-if="productlist[activeProduct].spot[2]">
                            <h2>{{productlist[activeProduct].spot[2].value}}</h2>
                            <p class="small">{{productlist[activeProduct].spot[2].key}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
  });
let header = new Vue({
    el: "v-header",
    data: {
        activeCate: 0,
        activeProduct: 0,
        category: [
            "智能同步总线iRAX",
            "RapidIO总线",
            "高性能存储",
            "综合电子系统仿真和设计",
            "嵌入式计算机处理",
        ],
        productList: [
            {
                id:"1",
                name:"WRD 4300",
                brief:"WRD 4300 是一款非常高效的存储设备，它能满足需要高速高可靠通信存储环境的应用场景。",
                category: [0,1,2,3,4],
                price:'12,300',
                spot:[
                    {
                        key:"浮点计算性能",
                        value:"230 GFLOPs"
                    },
                    {
                        key:"读写延迟",
                        value:"12ms"
                    },
                    {
                        key:"芯片型号",
                        value:"Hstream-238",
                    },
                ]
            },
        ],
    },
    methods: {
        setProduct: function (id) {
            this.activeProduct = id;
        },
        setCate: function (id) {
            this.activeCate = id;
            this.activeProduct = 0;
        }
    },
});
$(function () {
    $.post(
        "/manage/product_list.php",
        null,
        function (e) {
            e = JSON.parse(e);
            //console.log(e);
            if (e.success){
                header.productList = e.productList;
                // initialization of HSMegaMenu component
                $('.js-mega-menu').HSMegaMenu({
                    event: 'hover',
                    pageContainer: $('.container'),
                    breakpoint: 767.98,
                    hideTimeOut: 0
                });
                // initialization of header
                $.HSCore.components.HSHeader.init($('#header'));
            }
        }
    );
});